<template>
  <div class="body fixed mobile ">
    <section class="header store_register">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" />
        </a>
      </div>
      <span class="fL nowrap">{{ $t('send-gold') }}</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third">草稿暫存</a> -->
      </div>
    </section>
    <div class="main">
      <div class="bg-white flex p-1 mt-4 items-center justify-between text-lg rounded-lg">
        <div class="flex items-center">
          <CoinIcon />
          <span>{{ $t('hold-coins') }}</span>
        </div>
        <span>{{ $t('coin-unit', { coin: displayUserPointBalance }) }}</span>
      </div>
      <div class="mt-2 mb-2">{{ $t('send-desc') }}</div>
      <form class="mt-1" @submit.prevent="toConfirmSend">
        <!-- 轉贈對象 -->
        <div class="dropdown px-4 py-2 rounded bg-white mt-2">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-target') }}<span class="text-red-500">*</span></span>
          <select required v-model="form.target" name="type" class="fM rounded pl-4" dir="rtl">
            <option value="target_member">{{ $t('target-member') }}</option>
            <option value="target_group">{{ $t('send-group-target') }}</option>
          </select>
          <div class="arrow pr-2">
            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
          </div>
        </div>

        <!-- 社福團體 -->
        <div v-if="isGroup" class="dropdown px-4 py-2 rounded bg-white mt-2 disabled">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-group-target') }}<span class="text-red-500">*</span></span>
          <select
            required
            disabled
            v-model="form.target"
            name="type"
            class="fM rounded pl-4"
            dir="rtl"
            :placeholder="$t('please-select')"
          >
          </select>
          <div class="arrow pr-2">
            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
          </div>
        </div>

        <!-- 手機號碼 -->
        <div v-if="!isGroup" class="dropdown px-4 py-2 rounded bg-white mt-2">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('phone-number') }}<span class="text-red-500">*</span></span>
          <input
            required
            type="tel"
            class="fM text-left pl-4"
            placeholder="請填寫"
            v-model="form.phone"
          />
          <!-- <select name="type" class="fM rounded" dir="rtl">
          </select>
          <div class="arrow">
            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs"></font-awesome-icon>
          </div> -->
        </div>

        <!-- 轉贈數量 -->
        <div class="dropdown px-4 py-2 rounded bg-white mt-2" :class="isGroup?'disabled':''">
          <div class="icon">
            <font-awesome-icon :icon="['fas', 'gift']" />
          </div>
          <span class="fM nowrap gray">{{ $t('send-amount') }}<span class="text-red-500">*</span></span>
          <input
            id="amountInput"
            type="number"
            class="fM text-left pl-4 w-full"
            min="0"
            required
            :max="displayUserPointBalance"
            :value="form.amount"
            @input="handleChangeAmount"
          >
        </div>

        <div class="p-1 rounded flex items-center bg-white mt-2 justify-between fM">
          <span>{{ $t('banlance-after-send') }}</span>
          <span>{{ $t('coin-unit', { coin: remainingPoint }) }}</span>
        </div>
        <div class="flex mt-2">
          <button type="submit" :disabled="cannotSend" class="rounded p-1 flex-1 fM">{{ $t('confirm-send') }}</button>
        </div>
      </form>

      <section v-if="showConfirm" class="popup">
        <div class="popBody">
          <section class="popHeader">
            <span class="fL text-center">{{ $t('confirm-send-title') }}</span>
          </section>
          <section class="popMain" style="margin-top: 0;">
            <!-- <div class="fM text-center">
              {{ $t('confirm-send-title') }}
            </div> -->
            <span class="text-center mt-2">{{ $t('confirm-send-desc', { coin: this.form.amount, user: this.form.phone }) }}</span>
          </section>
          <section class="popFooter">
            <button class="button rounded white cancel fM" @click="showConfirm = false">{{ $t('cancel') }}</button>
            <button class="button submit rounded" @click="send" :disabled="isSending">
              <span class="fM">{{ $t('confirm') }}</span>
            </button>
          </section>
        </div>
      </section>
      <section v-if="showResult" class="popup">
        <div class="popBody">
          <section class="popHeader">
            <span class="fL text-center" v-if="isFail">
              {{ $t('send-fail') }}
            </span>
            <span class="fL text-center" v-else>
              {{ $t('send-success') }}
            </span>
          </section>
          <section class="popMain" style="margin-top: 0;">
            <div v-if="!isFail">
              <span class="text-center mt-2">{{ $t('send-success-desc', { coin: this.form.amount, user: this.form.phone }) }}</span>
            </div>
          </section>
          <section class="popFooter">
            <button class="button submit rounded" @click="showResult = false">
              <span class="fM">{{ $t('confirm') }}</span>
            </button>
          </section>
        </div>
      </section>

      <p v-if="isGroup" class="fM text-center mt-4">{{ $t('wait-group') }}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';

import CoinIcon from '../components/Icons/CoinIcon.vue';

export default {
  name: "Send",
  components: {
    CoinIcon,
  },
  data() {
    return {
      amount: 0,
      showConfirm: false,
      showResult: false,
      isSending: false,
      isFail: false,
      form: {
        target: "target_member",
        phone: "",
        amount: 0,
      },
    }
  },
  created() {
    this.$store.dispatch("getUserPoints")
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
    }),
    displayUserPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    remainingPoint() {
      return this.displayUserPointBalance - this.form.amount;
    },
    cannotSend() {
      if (this.isGroup) return true
      return this.form.amount <= 0 || this.form.amount > this.displayUserPointBalance;
    },
    isGroup() {
      return this.form.target === 'target_group'
    }
  },
  methods: {
    toConfirmSend() {
      this.showConfirm = true
    },
    async send() {
      const url = `${process.env.VUE_APP_API_HOST}/points/v1/merchants/yunlin/members/${this.user.userId}/transfer`;
      try {
        this.isSending = true;
        this.isFail = false;
        await axios(
          {
            url,
            method: "POST",
            data: {
              receiverPhoneNumber: this.form.phone,
              points: this.form.amount,
              batchId: '',
              note: '會員間點數轉移'
            }
          }
        )
        this.$store.dispatch("getUserPoints")
      } catch (error) {
        console.log('error', error);
        // TODO: Get error message
        this.isFail = true;
      } finally {
        this.showConfirm = false;
        this.isSending = false;
        this.showResult = true;
      }
    },
    handleChangeAmount(event) {
      const value = parseInt(event.target.value, 10) || 0;
      if (value > this.displayUserPointBalance) {
        setTimeout(() => {
          this.form.amount = this.displayUserPointBalance;
          const dom = document.getElementById("amountInput");
          dom.value = this.displayUserPointBalance;
        }, 100);
      } else {
        this.form.amount = value;
      }
    },
    checkMaxValue() {
      if (this.number > this.displayUserPointBalance) {
        this.number = this.displayUserPointBalance;
      }
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(to, from);
  //   next((vm) => {
  //     let that = vm;
  //     if (that.user && that.user.userId) {
  //       //
  //     } else {
  //       next("/error");
  //     }
  //   });
  // },
}
</script>
<style scoped lang="scss">
button {
  border: 0;
  color: white;
  background-color: var(--c01);

  &:disabled {
    background: #bdbdbd;
  }

  &.cancel {
    color: black;
    border: 1px solid black;
  }
}

.popup {
  display: flex !important;
}
.dropdown {
  box-sizing: border-box;
}

.disabled {
  opacity: 1 !important;
  background-color: var(--cOther1) !important;

  select {
    background-color: var(--cOther1) !important;
  }
}

select {
  text-align: left;
  padding-left: 24px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>